class Header{
    constructor(){
        this.header = document.querySelector('.header');
        this.hamburger = this.header ? this.header.querySelector('.header__hamburger__button') : false;
        this.nav = this.header.querySelectorAll('.header__nav ul li');
        !this.hamburger || [this.header.querySelector('.header > span'), this.hamburger].forEach(element => element.addEventListener('click', () => this.toggleNav()))
        !this.nav || this.nav.forEach(el => el.querySelector('a').addEventListener('click', e => this.scrollNav(e, el.querySelector('a'))));
    }

    // Uwaga Aby poprawić naprawianie przewijania nalezy dodać co aby wszystko wróciło do piertnej wysokości najlepiej
    // podczas przewijania bedzie dodać jakaś klase aby wysokości ustawić na 0 do przemyślenia 
    scrollNav(e, el){
        e.preventDefault();
        let id = el.hash,
            y = document.querySelector(id) ? document.querySelector(id).getBoundingClientRect().top + window.scrollY : 0;
        window.scroll({
            top: y - 120,
            behavior: 'smooth'
        });
        this.header.classList.remove('active');
        this.hamburger.classList.remove('active');
    }
    toggleNav(){
        this.header.classList.toggle('active');
        this.hamburger.classList.toggle('active');
    }
}

export default Header;