//import Aos from 'aos';
import Aos from 'aos';
import Swiper from 'swiper/js/swiper.js';
//import { tween } from 'popmotion';

class Sections{
    constructor() {
        this.sections = document.querySelector('.section') ? document.querySelectorAll('.section') : false;
        this.swiper_full = document.querySelector('.swiper__box') ? document.querySelectorAll('.swiper__box') : false;
        this.section_four = document.querySelector('.section_four') ? document.querySelectorAll('.section_four') : false;
        this.text = document.querySelector('.animate-text') ? document.querySelectorAll('.animate-text') : false;
        this.video = document.querySelector('.section_two__video') ? document.querySelectorAll('.section_two__video') : false;
        this.email = document.querySelector('.section_six__form') ? document.querySelectorAll('.section_six__form') : false;
    
        window.innerWidth >=767 ? !this.sections || ['scroll', 'DOMContentLoaded'].forEach(event => window.addEventListener(event, () => this.sectionScroll())) : false;
        !this.swiper_full || this.swiper_full.forEach(el => this.swiperBox(el));
        !this.section_four || this.section_four.forEach(el => this.swiperFour(el));
        !this.text || this.text.forEach(el => {
            ['scroll', 'DOMContentLoaded'].forEach(event => window.addEventListener(event, () => {
                if (!el.classList.contains('active') && (el.getBoundingClientRect().top - window.innerHeight) < -Math.abs(window.innerHeight/4))
                    this.textAnimation(el);
            }));
        });
        !this.video || this.video.forEach(el => this.sectionVideo(el));
        !this.email || this.email.forEach(el => this.sectionEmail(el));
    }


    sectionEmail(el){
        let form = el.querySelector('.form');

        form.addEventListener('submit', e => {
           // e.preventDefault();
           console.log(form.elements);
           let obc_inputs = {
               name: form.querySelector('input[name=name]').value,
               email: form.querySelector('input[type=email]').value,
               text: form.querySelector('textarea').value

           }
           if(obc_inputs.name != '' && obc_inputs.email != ''){
               this.sendAjax(obc_inputs, el);
           }else{
               console.log('nie działa');

           }
        })
    }

    sendAjax(el, element){
        console.log(el);
        const http = new XMLHttpRequest(),
            url = 'email.php',
            data = el,
            params = this.objectDecapit(data);
        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.onloadstart = () => element.querySelector('button').classList.add('loader');
        http.onreadystatechange = () => {
            if (http.readyState == 4 && http.status == 200) {
                console.log(http.response);
                if(http.response === 'success'){
                    if (element.querySelector('.error')){
                        let error = element.querySelector('.error');
                        error.classList.remove('error');
                        error.classList.add('succes');
                        error.innerText = 'Message was sent';
                    } else if (element.querySelector('.succes')){
                        let succes = element.querySelector('.succes');
                        succes.innerText = 'Message was sent';
                    }else{
                        let p = document.createElement('p');
                        p.classList.add('succes');
                        p.innerText = 'Message was sent';
                        element.appendChild(p);

                    }
                }else{
                    if (element.querySelector('.succes')) {
                        let succes = element.querySelector('.succes');
                        succes.classList.remove('succes');
                        succes.classList.add('error');
                        succes.innerText = 'Sending failed';
                    } else if (element.querySelector('.error')) {
                        let succes = element.querySelector('.error');
                        succes.innerText = 'Sending failed';
                    } else {
                        let p = document.createElement('p');
                        p.classList.add('error');
                        p.innerText = 'Sending failed';
                        element.appendChild(p);

                    }
                }
            }
        };
        http.onloadend = () => element.querySelector('button').classList.remove('loader');
        http.send(params);
    }

    objectDecapit(data) {
        let html = '';
        for (let [key, value] of Object.entries(data)) {
            if (typeof value === 'object') {
                let name = [];
                for (let [key1, value2] of Object.entries(value)) {
                    name.push(value2);
                }
                html += `${key}=${name}&`
            } else {
                html += `${key}=${value}&`
            }
        }
        return html.substring(0, html.length - 1);
    };

    sectionVideo(el){
        ['scroll', 'DOMContentLoaded'].forEach(event => window.addEventListener(event, () => {
            let element = el.querySelector('video');
            if (element.getBoundingClientRect().top - (window.innerHeight / 1.2) < 0) {
                if (element.getBoundingClientRect().top + element.getBoundingClientRect().height > 0) element.play();
                else element.pause();

            } else element.pause();
            // console.log(el.querySelector('video').getBoundingClientRect().top - window.innerHeight)
            // if (!el.classList.contains('active') && el.querySelector('video').getBoundingClientRect().top - window.innerHeight > 0 && el.querySelector('video').getBoundingClientRect().top > 0){
            //     el.classList.add('active');
            //     el.querySelector('video').play();

            // }else{
            //     el.classList.remove('active');
            //     el.querySelector('video').pause();

            // }
            // if (!el.classList.contains('active') && (el.getBoundingClientRect().top - window.innerHeight) < -50){
            //     el.classList.add('active');
            //     console.log(el.querySelector('video').getBoundingClientRect().top);

            // } else if (el.querySelector('video').getBoundingClientRect().top <= -50){
            //     el.classList.remove('active');
            // } 
            //console.log(el.querySelector('video').offsetHeight)
        }));
         
    }


    textAnimation(el) {
        el.classList.add('active');
        let arr_text = el.innerText.split(' '),
            time = el.dataset.time ? Number(el.dataset.time) : 80,
            text = ''; 
        arr_text.forEach(a => {
            text += a != ' ' ? `<span>${a}</span>` : "&nbsp;";
        });
        el.innerHTML = text;
        // el.querySelectorAll('span').forEach(item => {
        //     let oneLetter = '';
        //     item.innerText.split('').forEach(l => {
        //         oneLetter += `<span>${l}</span>`;
        //     })
        //     item.innerHTML = oneLetter;
            
        // })
        el.querySelectorAll('span').forEach((span, i) => {
            setTimeout(() => {
                span.classList.add('active');
            }, i * time);
            // span.querySelectorAll('span').forEach((l, index) => {
            //     setTimeout(() => {
            //         l.classList.add('active');
            //     }, (index + i) * time);
            // })
        })
    }
    sectionScroll(){
        this.sections.forEach(el => {
            if (el.getBoundingClientRect().top + (el.offsetHeight - window.innerHeight) <= 0) 
                el.querySelector('.section__scroll').style = `transform:translateY(${Math.abs(el.getBoundingClientRect().top + (el.offsetHeight - (window.innerHeight)))}px)`;
            else el.querySelector('.section__scroll').style = `trnasform:trnasalteY(0)`;
        })
    }

    swiperBox(el) {
        new Swiper(el.querySelector('.mySwiper'), {
            slidesPerView: 1,
            spaceBetween: 20,
            loop:false,
            navigation: {
                nextEl: el.querySelector(".swiper-button-next"),
                prevEl: el.querySelector(".swiper-button-prev"),
            },
            breakpoints: {
                550: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 30
                }
            },
            on: {
                init: function(){
                    if(window.innerWidth >= 550){
                        el.querySelector('.swiper-arrow__number').querySelectorAll('span')[0].innerText = this.realIndex + 1;
                        el.querySelector('.swiper-arrow__number').querySelectorAll('span')[1].innerText = el.querySelectorAll('.swiper-slide').length > 3 ? el.querySelectorAll('.swiper-slide').length - 2 : el.querySelectorAll('.swiper-slide').length;
                        //el.querySelectorAll('.swiper-slide')[0].querySelector('svg').dispatchEvent(new Event('click'));
                        el.querySelectorAll('.swiper-slide')[1].querySelector('svg') ? el.querySelectorAll('.swiper-slide')[1].querySelector('svg').dispatchEvent(new Event('click')) : false;
                    }else{ 
                        el.querySelector('.swiper-arrow__number').querySelectorAll('span')[0].innerText = this.realIndex + 1;
                        el.querySelector('.swiper-arrow__number').querySelectorAll('span')[1].innerText = el.querySelectorAll('.swiper-slide').length > 3 ? el.querySelectorAll('.swiper-slide').length : el.querySelectorAll('.swiper-slide').length;
                        el.querySelectorAll('.swiper-slide')[0].querySelector('svg').dispatchEvent(new Event('click'));
                    }
                },
                slideChange: function () {
                    if(window.innerWidth >= 550){
                        el.querySelectorAll('.swiper-slide')[this.activeIndex + 1] ? el.querySelectorAll('.swiper-slide')[this.activeIndex + 1].querySelector('svg').dispatchEvent(new Event('click')) : '';
                        el.querySelector('.swiper-arrow__number').querySelectorAll('span')[0].innerText = this.realIndex + 1;
                        el.querySelectorAll('.swiper-slide')[el.querySelectorAll('.swiper-slide').length - 1].querySelector('svg') ? el.querySelectorAll('.swiper-slide')[el.querySelectorAll('.swiper-slide').length - 1].querySelector('svg').dispatchEvent(new Event('click')) : false;
                        if (this.activeIndex === el.querySelectorAll('.swiper-slide').length - 3) {
                        }
                    }else{
                        el.querySelectorAll('.swiper-slide')[this.activeIndex].querySelector('svg') ? el.querySelectorAll('.swiper-slide')[this.activeIndex].querySelector('svg').dispatchEvent(new Event('click')) : '';
                        el.querySelector('.swiper-arrow__number').querySelectorAll('span')[0].innerText = this.realIndex + 1;
                    }
                },
            }
        });
    }

    swiperFour(el){
        new Swiper(el.querySelector('.mySwiper'), {
            slidesPerView: 1,
            spaceBetween: 20,
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar",
            },
            navigation: {
                nextEl: el.querySelector(".swiper-button-next"),
                prevEl: el.querySelector(".swiper-button-prev"),
            },
            breakpoints: {
                550: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 30
                }
            },
            on:{
                init: function () {
                    if(window.innerWidth >= 550){
                        el.querySelector('.swiper-arrow__number').querySelectorAll('span')[0].innerText = this.realIndex + 1;
                        el.querySelector('.swiper-arrow__number').querySelectorAll('span')[1].innerText = el.querySelectorAll('.swiper-slide').length > 3 ? el.querySelectorAll('.swiper-slide').length - 2 : el.querySelectorAll('.swiper-slide').length;
                        el.querySelectorAll('.swiper-slide')[0].querySelector('svg') ? el.querySelectorAll('.swiper-slide')[0].querySelector('svg').dispatchEvent(new Event('click')) : false;
                        el.querySelectorAll('.swiper-slide')[1].querySelector('svg').dispatchEvent(new Event('click'));
                    }else{
                        el.querySelector('.swiper-arrow__number').querySelectorAll('span')[0].innerText = this.realIndex +1;
                        el.querySelector('.swiper-arrow__number').querySelectorAll('span')[1].innerText = el.querySelectorAll('.swiper-slide').length > 3 ? el.querySelectorAll('.swiper-slide').length: el.querySelectorAll('.swiper-slide').length;
                        el.querySelectorAll('.swiper-slide')[0].querySelector('svg') ? el.querySelectorAll('.swiper-slide')[0].querySelector('svg').dispatchEvent(new Event('click')) : false;
                        
                    }
                },
                slideChange: function(){
                    //el.querySelector('.swiper-slide-prev').querySelector('svg').dispatchEvent(new Event('click'));
                    if(window.innerWidth >= 550){
                        el.querySelectorAll('.swiper-slide')[this.activeIndex + 1].querySelector('svg').dispatchEvent(new Event('click'));
                        el.querySelector('.swiper-arrow__number').querySelectorAll('span')[0].innerText = this.realIndex + 1;
                        if (this.activeIndex === el.querySelectorAll('.swiper-slide').length -3){
                            el.querySelectorAll('.swiper-slide')[el.querySelectorAll('.swiper-slide').length-1].querySelector('svg').dispatchEvent(new Event('click'));
                        }

                    }else{
                        el.querySelectorAll('.swiper-slide')[this.activeIndex].querySelector('svg').dispatchEvent(new Event('click'));
                        el.querySelector('.swiper-arrow__number').querySelectorAll('span')[0].innerText = this.realIndex + 1;
                    }
                },
            }
        });
    }
};

export default Sections;